// Generated by Framer (2263e31)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/eNHG5iNng";
import * as sharedStyle1 from "../css/zYg8XiTxp";

const cycleOrder = ["LFrOkUbrs", "IinEy955V"];

const serializationHash = "framer-G62rD"

const variantClassNames = {IinEy955V: "framer-v-1qhzlex", LFrOkUbrs: "framer-v-9gqixj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "LFrOkUbrs", "Variant 2": "IinEy955V"}

const getProps = ({height, id, image, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LFrOkUbrs", XC3FUO0V5: title ?? props.XC3FUO0V5 ?? "Hiya!", YgTff00z5: image ?? props.YgTff00z5 ?? {src: "https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg?scale-down-to=512", srcSet: "https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg?scale-down-to=512 512w, https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg?scale-down-to=1024 1024w, https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg?scale-down-to=2048 2048w, https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg?scale-down-to=4096 4096w, https://framerusercontent.com/images/ndOd1ax25DVPhrJNVjUit7KAGug.jpg 7087w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XC3FUO0V5, YgTff00z5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LFrOkUbrs", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-G62rD", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-9gqixj", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LFrOkUbrs"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-34fec16c-bfec-4d4e-b8cf-818182dd95dc, rgb(15, 15, 15))", ...style}} {...addPropertyOverrides({IinEy955V: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-1l0u4wh"} data-styles-preset={"eNHG5iNng"}>Hiya!</motion.h1></React.Fragment>} className={"framer-opykax"} layoutDependency={layoutDependency} layoutId={"TjbFme2UH"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={XC3FUO0V5} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({IinEy955V: {children: <React.Fragment><motion.h2 className={"framer-styles-preset-185mgg0"} data-styles-preset={"zYg8XiTxp"}>Hiya!</motion.h2></React.Fragment>}}, baseVariant, gestureVariant)}/><Image background={{alt: "", fit: "fill", intrinsicHeight: 5507, intrinsicWidth: 7087, pixelHeight: 5507, pixelWidth: 7087, ...toResponsiveImage(YgTff00z5)}} className={"framer-14v8jd9"} layoutDependency={layoutDependency} layoutId={"YZHeWOVSw"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-G62rD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G62rD .framer-1g4ov9b { display: block; }", ".framer-G62rD .framer-9gqixj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-G62rD .framer-opykax { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-G62rD .framer-14v8jd9 { bottom: 0px; flex: none; left: 0px; mix-blend-mode: multiply; overflow: hidden; position: absolute; right: 0px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G62rD .framer-9gqixj { gap: 0px; } .framer-G62rD .framer-9gqixj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-G62rD .framer-9gqixj > :first-child { margin-left: 0px; } .framer-G62rD .framer-9gqixj > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 128
 * @framerIntrinsicWidth 243
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"IinEy955V":{"layout":["auto","auto"]}}}
 * @framerVariables {"XC3FUO0V5":"title","YgTff00z5":"image"}
 * @framerImmutableVariables true
 */
const FramerBcArB6gPd: React.ComponentType<Props> = withCSS(Component, css, "framer-G62rD") as typeof Component;
export default FramerBcArB6gPd;

FramerBcArB6gPd.displayName = "Text with Background";

FramerBcArB6gPd.defaultProps = {height: 128, width: 243};

addPropertyControls(FramerBcArB6gPd, {variant: {options: ["LFrOkUbrs", "IinEy955V"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, XC3FUO0V5: {defaultValue: "Hiya!", displayTextArea: false, title: "Title", type: ControlType.String}, YgTff00z5: {__defaultAssetReference: "data:framer/asset-reference,ndOd1ax25DVPhrJNVjUit7KAGug.jpg?originalFilename=photo-1622110674153-a1326259c41e%3Fcrop%3Dentropy%26cs%3Dsrgb%26fm%3Djpg%26ixid%3DM3wxMzc5NjJ8MHwxfHNlYXJjaHwxM3x8ZmxhbWV8ZW58MHx8fHwxNjk0MTI2MDI5fDA%26ixlib%3Drb-4.0.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerBcArB6gPd, [...sharedStyle.fonts, ...sharedStyle1.fonts])